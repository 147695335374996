import styled from "styled-components"
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
} from "swiper"
require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")
require("swiper/components/effect-fade/effect-fade.scss")

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay])

const StyledSwiper = styled.div`
  .swiper-container {
    overflow: visible;

    .swiper-pagination {
      .swiper-pagination-bullet {
        background: white;
        width: 15px;
        height: 15px;
        border: 2px solid white;
        opacity: 1;
        background-color: transparent;

        @media (max-width: ${props => props.theme.sizes.xs}) {
          width: 10px;
          height: 10px;
        }
      }

      .swiper-pagination-bullet-active {
        background-color: white;
      }
    }

    .swiper-button-prev {
      left: -10px;
    }

    .swiper-button-next {
      right: -10px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: white;

      @media (max-width: ${props => props.theme.sizes.xs}) {
        display: none;
      }
    }
  }
`

const StyledSlideContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  @media (max-width: ${props => props.theme.sizes.xs}) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
`

const StyledIcon = styled.div`
  width: 65px;
  height: 80px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    transform: ${props => `scale(${props.zoom})`};

    @media (max-width: ${props => props.theme.sizes.xs}) {
      transform: ${props => `scale(${0.8 * props.zoom})`};
    }
  }
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: white;

    p {
      color: var(--bg-blue);
    }

    img {
      filter: invert(75%);
    }
  }

  p {
    width: 85%;
    margin: 0 auto;
    line-height: 1.15em;
    hyphens: auto;

    @media (max-width: ${props => props.theme.sizes.xs}) {
      width: 95%;
    }
  }
`

const StyledH3 = styled.h3`
  max-width: ${props => props.theme.sizes.sm};
  margin: 1em auto;
`

export default function AngebotSwiper({ angebote }) {
  return (
    <StyledSwiper className="mb-8 mb-md-12">
      <Swiper
        slidesPerView={1}
        // pagination={{ clickable: true }}
        // navigation
        loop
        // autoplay={{ delay: 6000 }}
        autoHeight={true}
        // breakpoints={{
        //   1600: {
        //     autoHeight: false,
        //   },
        // }}
      >
        {angebote.map(angebot => (
          <SwiperSlide className="py-5 py-md-12">
            <div className="container inner my-auto">
              <StyledH3 className="text-center">{angebot.title}</StyledH3>
              <StyledSlideContent className="mt-0 mb-12 my-md-8">
                {angebot.acf_angebot.items.map(item => (
                  <StyledItem className="my-4 py-3">
                    <StyledIcon zoom={item.zoom}>
                      <img
                        src={item.icon?.sourceUrl}
                        alt={`icon-${item.titel}`}
                        className="my-4"
                      />
                    </StyledIcon>
                    <p className="text-center mt-3 mt-md-5">{item.titel}</p>
                  </StyledItem>
                ))}
              </StyledSlideContent>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSwiper>
  )
}
