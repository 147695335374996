import React from "react"
import { Helmet } from "react-helmet"
import AngebotSwiper from "../components/AngebotSwiper"
import { graphql } from "gatsby"

export default function KontaktPage({ data }) {
  const angebote = data.allWpAngebot.nodes

  return (
    <div className="container my-auto">
      <Helmet>
        <title>Angebot – Thierry Seiler Webseiten und Webapps, Zürich</title>
        <meta
          property="og:title"
          content="Thierry Seiler Webseiten und Webapps, Zürich"
        />
        <meta
          property="og:description"
          content="Ich bin dein Partnerfür das Programmieren von Webseiten & Webapps"
        />
      </Helmet>

      {/* <AngebotList /> */}

      <AngebotSwiper angebote={angebote} />
    </div>
  )
}

export const pageQuery = graphql`
  query AllAngeboteData {
    allWpAngebot(sort: { order: ASC, fields: menuOrder }) {
      nodes {
        id
        title
        acf_angebot {
          items {
            titel
            icon {
              sourceUrl
            }
            zoom
          }
        }
      }
    }
  }
`
